import * as React from 'react';
import ReactDOM from "react-dom/client";
import * as serviceWorker from './serviceWorker';
import {BrowserRouter, Route, Routes} from "react-router-dom";
import EditRace from "./App/Pages/EditRace";
import EditActiveRace from "./App/Pages/EditActiveRace";
import {luleaOpenTheme} from "./Styles/Theme";
import {ThemeProvider} from "@mui/system";
import LazyDog from "./App/Pages/LazyDog";
import MainMenu from "./App/Pages/MainMenu";
import ListFinals from "./App/Pages/ListFinals";
import WatchRace from "./App/Pages/WatchRace";
const root = ReactDOM.createRoot(
    document.getElementById("app")
);
// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
import { getAnalytics } from "firebase/analytics";
// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
    apiKey: "AIzaSyBtt0fzVGthOZ7hqhGdVNWfYmFrVGYpw6I",
    authDomain: "lulea-open.firebaseapp.com",
    projectId: "lulea-open",
    storageBucket: "lulea-open.appspot.com",
    messagingSenderId: "301188127288",
    appId: "1:301188127288:web:330f4f498402b03b92a4cd",
    measurementId: "G-0MRYNC7G0L"
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
getAnalytics(app);

root.render(<ThemeProvider theme={luleaOpenTheme}><BrowserRouter>
    <Routes>
        <Route path="/" element={<MainMenu/>}/>
        <Route path="editFinal" element={<EditRace/>}/>
        <Route path="editActiveFinal" element={<EditActiveRace/>}/>
        <Route path="lazyDog" element={<LazyDog/>}/>
        {/*<Route path="/" element={<ListFinals/>}/>*/}
        {/*<Route path="/final" element={<WatchRace/>}/>*/}
    </Routes>

</BrowserRouter></ThemeProvider>);

// ReactDOM.render(
//   <React.Fragment>
//     {/* CssBaseline kickstart an elegant, consistent, and simple baseline to build upon. */}
//     <CssBaseline />
//     <App />
//   </React.Fragment>,
//   document.getElementById('root'),
// );

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
